<template>
  <div class="tibox">
    <div class="timain">
      <div class="tibiao">
        <el-table :data="tilist" stripe height="90%" width="100%" :summary-method="getSummaries" show-summary>
          <el-table-column label="子公司名称" width="350">
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>

          <el-table-column label="2023年12月" v-if="a12">
            <el-table-column prop="m12" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m12 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm12" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm12 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm12" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm12 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年11月" v-if="a11">
            <el-table-column prop="m11" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m11 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm11" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm11 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm11" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm11 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年10月" v-if="a10">
            <el-table-column prop="m10" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m10 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm10" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm10 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm10" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm10 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年9月" v-if="a9">
            <el-table-column prop="m9" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m9 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm9" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm9 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm9" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm9 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年8月" v-if="a8">
            <el-table-column prop="m8" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m8 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm8" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm8 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm8" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm8 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年7月" v-if="a7">
            <el-table-column prop="m7" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m7 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm7" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm7 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm7" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm7 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年6月" v-if="a6">
            <el-table-column prop="m6" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m6 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm6" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm6 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm6" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm6 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年5月" v-if="a5">
            <el-table-column prop="m5" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m5 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm5" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm5 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm5" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm5 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年4月" v-if="a4">
            <el-table-column prop="m4" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m4 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm4" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm4 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm4" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm4 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="2023年3月" v-if="a3">
            <el-table-column prop="m3" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m3 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm3" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm3 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm3" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm3 }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column class-name="monthtit" label="2023年2月">
            <el-table-column prop="m2" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m2 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm2" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm2 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm2" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm2 }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column class-name="monthtit" label="2023年1月">
            <el-table-column prop="m1" label="订单量" width="100">
              <template #default="scope">
                {{ scope.row.m1 }}
              </template>
            </el-table-column>
            <el-table-column prop="mm1" label="订单金额" width="100">
              <template #default="scope">
                {{ scope.row.mm1 }}
              </template>
            </el-table-column>
            <el-table-column prop="mmm1" label="奖励" width="100">
              <template #default="scope">
                {{ scope.row.mmm1 }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="150">
            <template #default="scope">
              <router-link class="dingbtn" :to="`/OrderG?cid=${scope.row.cid}`">
                <el-button link type="primary" size="small">订单</el-button>
              </router-link>

              <router-link
                class="dingbtn"
                style="margin-left: 10px"
                :to="`/Finance?cid=${scope.row.cid}`"
              >
                <el-button link type="primary" size="small">财务</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import * as api2 from "../api/index.js";
import * as api from "../api/join.js";
import * as api1 from "../api/order";
import * as ola from "../api/olawebsock.js";
import Part from "../components/part.vue";
import { ElMessageBox } from "element-plus";
const value = ref("");
export default {
  components: { Part },
  data() {
    return {
      value,
      value1: "",
      value2: "",
      value3: "",
      keyword: "",
      total: "",
      tilist: [],
      tilist1: [],
      techlist: [],
      clook: "",
      crescueId: "",
      emicreg: "",
      etcprice: "",

      keytype: "",
      keytit: "",
      chanliang: "",
      chanzhi: "",
      yeji: "",
      tech: "",
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,
      a11: false,
      a12: false,
    };
  },
  async created() {
    this.res_getList();
    this.gettechlist();
  },

  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },



    async res_getList() {
      let res_getList = await api.getGrouplist();
      console.log("分公司列表", res_getList);
      this.tilist = res_getList.data;
      this.a2 = res_getList.a2;
      this.a3 = res_getList.a3;
      this.a4 = res_getList.a4;
      this.a5 = res_getList.a5;
      this.a6 = res_getList.a6;
      this.a7 = res_getList.a7;
      this.a8 = res_getList.a8;
      this.a9 = res_getList.a9;
      this.a10 = res_getList.a10;
      this.a11 = res_getList.a11;
      this.a12 = res_getList.a12;
    },
    async gettechlist() {
      let res = await api2.gettechlist(1);
      this.techlist = res.data;
    },
    jion(id) {
      this.crescueId = id;
      this.clook++;
      if (this.clook > 2) {
        this.clook = 1;
      }
    },
    async keyword1() {
      this.datas.carnumber = this.keytit;
      this.res_getCommission(this.datas);
    },
    async daochu() {
      let res = await api1.commissionlistExport(this.datas);
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },

  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getisFin() {
      return this.$store.state.isFin;
    },
  },
  watch: {},
};
</script>

<style>
.el-table thead.is-group th.el-table__cell {
  background-color: #f5f5f5;
  color: #666;
}
.monthtit {
  height: 40px !important;
  text-align: center !important;
}
</style>
<style lang="less" scoped>
.tibiao /deep/ .el-table .cell {
  white-space: pre-line;
}
.tibox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.timain {
  width: 1730px;
  height: 100%;
  margin-left: 190px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.tinav {
  display: flex;
  align-items: center;
}
.tihead {
  width: 100%;
  height: 120px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.left {
  float: left;
  // width: 300px;
  height: 32px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-left: 20px;
}
.jing {
  float: left;
  width: 32px;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
}
.tisou,
.tiru {
  width: 100%;
  height: 100%;
  border: 0;
  box-sizing: border-box;
  padding-left: 12px;
  outline: none;
}
.tiru {
  border: 1px solid #ccc;
}
.tishu {
  width: 250px;
  height: 32px;
  box-sizing: border-box;
  margin-left: 20px;
}
.btn {
  float: right;
  // width: 180px;
  height: 32px;
  opacity: 1;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
}
.tibiao {
  width: 100%;
  //   height: 665px;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  //   margin-top: 20px;
}
.tibiao /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table thead.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 40px !important;
  color: #000;
}
.tibiao /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.tibiao /deep/ .el-table__cell {
  padding: 0;
}
.jinXing-sorter {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 40px;
  bottom: 50px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.left /deep/ .el-select .el-input {
  width: 200px;
  margin-right: 20px;
}
.tibiao /deep/ .el-table-fixed-column--right:last-of-type {
  // width: 200px;
  // display: flex;
  // align-items: center;
  height: 70px !important;
}
.etcprice {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 30px;
  color: #606266;
}

.seek {
  float: left;
  // width: 200px;
  // height: 40px;
  background: #ffffff;
  // border: 1px solid #dadfe6;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 200px;
  height: 37px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  height: 32px;
  border: 1px solid #dadfe6;
}
.glass {
  // float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  margin-top: -1px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 14px;
  // margin-left: 13px;
  // margin-top: 13px;
  cursor: pointer;
}

.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.tinav /deep/ .el-range-editor.el-input__inner {
  width: 250px;
  margin: 0 !important;
}
.tinav /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}
.tiaobtn {
  margin-right: 10px;
}
.tibiao /deep/ .el-table__footer-wrapper tbody td.el-table__cell {
  font-size: 15px;
  color: #000;
  font-weight: bold;
}
</style>